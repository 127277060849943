<template>

  <surroundBox :name="description" :unit="unit" desc="产生的产值">
    <template slot="content">
      <div class="economicBenefits-echart" style="width:100%;height:100%"></div>
    </template>
  </surroundBox>
</template>

<script>
import * as echarts from "echarts";
import surroundBox from "../surroundBox/surroundBox";

export default {
  name: "economicBenefits",
  components: {
    surroundBox,
  },
  data() {
    return {
      description: "经济效益",
      chart: null,
      unit: "",
      dataSource: [],
    }
  },
  methods: {
    initCharts() {
      if (this.chart == null) {
        this.chart = echarts.init(document.querySelector(".economicBenefits-echart"))
      }
      let option = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(117, 154, 203, 0.39)',
          borderWidth: 0,
          textStyle: {
            color: '#fff',
          },
        },
        xAxis: {
          data: this.dataSource.map(item => item.time),
          //字体样式
          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',
              fontSize: 12
            },
            formatter: (params) => {
              return params + '年'
            }
          },

          //数据两端留白
          boundaryGap: true,
          //轴刻度线
          axisTick: {
            show: false,
          },
          //轴底部坐标轴线条
          axisLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#485566'
            },
          },
          //X轴网格线
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#485566'
            },
          },
        },
        yAxis: {
          name: '',
          nameTextStyle: {
            color: '#fff',
            fontSize: 12,
          },
          type: 'value',

          axisLabel: {
            show: true,
            textStyle: {
              color: '#fff',
              fontSize: 12
            }

          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#485566'
              //   color: 'rgba(204, 204, 204,0.5)',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#485566'
            },
          },
        },
        grid: {
          containLabel: true,
          top: 32,
          left: 25,
          right: 32,
          bottom: 16,
        },
        series: [
          {
            name: '',
            type: 'bar',
            barWidth: 20,
            color: '#3D32E8',
            barGap: 0,
            label: {
              show: false,
              position: 'top',
            },
            data: this.dataSource.map(item => {
              return {
                value: item.val,
                itemStyle: {
                  color: item.color
                }
              }
            }),
            // data: this.yData1,
          },
          // {
          //   name: '出栏',
          //   type: 'bar',
          //   barWidth: 11,
          //   color: '#9F32E8',
          //   label: {
          //     show: false,
          //     position: 'top',
          //   },
          //   // data: [3300, 4880, 4300, 3380],
          //   data: this.yData2,
          // },
        ],
      }
      this.chart.setOption(option)
    },
    loadData() {
      this.dataSource = []
      return this.$post(this.$api.STATICTICS_MAP.PLATFORM_INFO, {
        resourceTypeId: 181
      }).then(res => {
        if (res && res.length > 0) {
          let colors = ['#0263FF', '#FF7723', '#8E30FF', '#93E2C2']
          res.forEach((item, index) => {
            this.unit = item.contentUnit
            this.dataSource.push({
              time: item.year,
              val: +item.content,
              color: colors[index] ? colors[index] : ''
            })
          })
        }
      })
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.chart && this.chart.resize()
    })
    this.loadData().then(() => {
      this.initCharts()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
    })
  }
}
</script>
